/* toastr-custom.css */
  
  /* Estilização do texto do título */
  .toast-title {
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  /* Estilização do texto da mensagem */
  .toast-message {
    margin-top: 0.20rem;
    font-size: 0.875rem;
  }

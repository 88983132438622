/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "node_modules/ngx-toastr/toastr.css";
@import "./app/modules/utils/notification/toastr-custom.css";

html,
body {
  height: 100%;
  background-color: white; /** Update safe are color **/
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  /** Consider safe area for mobile app **/
  margin-top: env(safe-area-inset-top);
  margin-right: env(safe-area-inset-right);
  margin-bottom: env(safe-area-inset-bottom);
  margin-left: env(safe-area-inset-left);
}

.ngx-overlay {
  @apply backdrop-filter backdrop-blur-md bg-gray-700;
}

.ngx-overlay .ngx-loading-logo {
  @apply brightness-[2];
}